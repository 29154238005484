.main__conteiner{
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    padding: 70px;
    background-color: #0C1F34;
    margin-bottom: 30px;
    color: white;
    margin-top: 40px;
}

.sub__conteiner{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    align-items: center;
    flex-wrap: wrap;
}

.logo_image{
    min-width: 500px;
    width: 45%;
}

.text{
    font-size: 20px;
    min-width: 500px;
    width: 55%;
}

.title{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 45px;
    margin-bottom: 20px;
}

.btn_contac_us{
    font-size: 20px;
    font-family: serif;
    border-radius: 10px;
}

@media (max-width: 900px) {
    .sub__conteiner{
        /* flex-direction: column-reverse;  */
        align-items: center; 
        text-align: center; 
        width: 100%;
        margin-top: 20px;
    }

    .logo_image{
        min-width: 97%;
        border: 3px solid white;
        margin-bottom: 20px;
    }

    .title{
        font-size: 28px; 
        text-align: center;
    }

    .text {
        font-size: 16px;
        text-align: start;
        min-width: 100%;
    }

    p span{
        margin-right: 15px;
    }
}