/* ServiceDetails.module.css */

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .details {
    font-size: 1rem;
    color: #666;
  }
  
  .notFound {
    text-align: center;
    font-size: 1.5rem;
    color: #d9534f;
    margin: 50px 0;
  }
  