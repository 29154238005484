.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .serviceCard {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: calc(50% - 40px); /* Adjust the width based on the number of items per row */
    min-width: 400px;
    margin-bottom: 20px;
  }
  
  .serviceTitle {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .serviceDescription {
    display: flex;
    flex-direction: column;
  }
  
  .serviceDetail {
    margin-bottom: 15px;
  }
  
  .serviceType {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .serviceText {
    color: #666;
  }
  @media all and (max-width: 700px){
    .container{
      padding: 0;
    }
  }