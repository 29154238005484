:root{
    --blue:#0C1F34;
    --red:#D35400;
    }

.main__conteiner{
    display: flex;
    flex-direction: row;
    height: 90vh;
    align-items: center;
    border-bottom: 2px solid var(--red);
    justify-content: center;
    margin-bottom: 30px;
    overflow: hidden;
    background-image: url('./../../images/background-white_992209-47.avif');
    z-index: -1;
    background-repeat: repeat;
}

.avatar_image{
    width: 350px;
    border: 10px solid white;
    outline: 3px solid var(--red);

}


  
.right__conteiner{
    width: 45%;
    padding-right: 40px;
}

.subtitle{
    font-size: 20px;
    color: var(--red);
}

.title{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 55px;
    margin-bottom: 20px;
    color: var(--red);
}

.background{
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('./../../images/background-white_992209-47.avif');
    z-index: -1;
    background-repeat: repeat;
    /* background-size: contain; */
}


.socialMedia {
    margin-top: 20px;
  }
  
  .socialMedia a {
    color: var(--red);
    margin-right: 15px;
    text-decoration: none;
    transition: 1s;
  }
  
  .socialMedia a:hover {
    color: var(--blue);
  }

  .socialMedia a .icon{
    width: 50px;
    height: 50px ;
    padding: 10px;
    transition: 1s;
    border-radius: 50%;

  }
  .socialMedia a:hover .icon{
    padding: 7px;
    border-radius: 0;
    border: 4px solid #F3F3F3;
    outline: 2px solid var(--blue);

  }

@media (max-width: 768px) {
    .main__conteiner{
        flex-direction: column-reverse; 
        align-items: center; 
        text-align: center; 
        width: 100%;
    }
    
    .avatar__conteiner {
        margin-top: 20px;
    }

    .avatar_image {
        width: 200px;
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .right__conteiner {
        width: 90%; 
        padding-left: 5px; 
        text-align: start;
        margin-left: 5%;
    }

    .title {
        font-size: 28px; 
        text-align: center;
    }

    .subtitle {
        font-size: 16px;
    }
}