.title{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 45px;
    margin-bottom: 10px;
    text-align: center;
}


@media (max-width: 768px) {
    .title {
        font-size: 28px; 
    }

}