.subtitle{
    color: var(--blue)!important;
    font-size: 20px;
    text-align: center;
    font-family: Georgia, serif;
}

.main__conteiner{
    margin-bottom: 30px;
}

.mySwiper {
    width: 100%;
    /* height: 700px; */
    background-color: rgba(47, 135, 178, 0.416);
    padding: 50px !important;
    
}
  
.swiper__slide{

    background-color: var(--blue);
width: 500px;
height: 400px !important;
border: 3px solid var(--red);
display: flex;
justify-content: center !important;
align-items: center !important;
overflow: hidden;
/* background-color: black; */
position: relative;
transition: 1s;

}

.card__container{
display: flex;
flex-direction: column;
width: 380px;
overflow: hidden;
padding: 0 !important;
height: 100%;
}

.header{
width: 100%;
height: 90px !important;
position: relative;
border-bottom: 2px solid var(--red);
margin-top: 0 !important;
}


.sub__card__container{
background-color: transparent !important;
margin-top: 20px;
min-height: 200px;
}

.title{
    font-size: 35px !important;
    text-align: center;
}

.title,
.card__title
{
color: var(--red) !important;
text-transform: capitalize;
font-size: 18px;
font-weight: bold

}


.swiper__slide:hover{
cursor: pointer;

}


.important_text,
.text{
font-size: 16px;
color: white !important;
text-align: start;
}

.text{
font-weight:500 !important;
}
.important_text{

font-size: 17;
font-weight:bolder;
}

.backgrond__container{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-image: url('./../../images/logo-removebg-preview\ \(1\).png');
background-size:70%;
background-repeat: no-repeat;
background-position: center;
opacity: 0.7;

}


.card__subtitle{
    text-transform: uppercase;
    font-family: Georgia, serif;
    font-size:16px;
    color:white
}
.card__title{
    margin-top: 10px ;
}
.text{
    color:white;
}

.more_info_button{
    margin-bottom: 20px !important;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    font-style: none;
}


.link{
    text-decoration: none !important;
}


@media all and (max-width: 700px){
.why__us__conteiner{
    min-width: 100%;
    padding: 5px;
}

.sub__card__container{
    background-color: transparent !important;
}

.important_text{

    font-size: 18;
    font-weight:initial;
}
.main__conteiner{
    padding: 10px;
    text-align: center;
}

.more_info_button{

}

}