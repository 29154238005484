.chatbot {
    width: 320px;
    height: 400px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: Arial, sans-serif;
    position: fixed; 
    bottom: 20px;
    right: 20px; 
    z-index: 100 !important; 
    margin-bottom: 0; 
    margin-left: 0; 
    display: flex;
  }
  .btn_chat_bot{
    
    position: fixed; 
    bottom: 20px;
    right: 20px; 
    z-index: 100 !important; 
    margin-bottom: 0; 
    margin-left: 0; 
  }
  
  .chatbot_messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .message {
    margin: 5px 0;
    padding: 8px 12px;
    border-radius: 5px;
  }
  
  .message.bot {
    background-color: #f1f1f1;
    align-self: flex-start;
  }
  
  .message_user {
    background-color: var(--blue);
    color: white;
    align-self: flex-end;
  }
  
  .chatbot_input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  .chatbot_input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .chatbot_input button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    background-color: var(--blue);
    color: white;
    cursor: pointer;
  }
  
  .chatbot_input button:hover {
    background-color: var(--blue);
  }
  

  .btn_chat_bot{
    background-color: transparent;
    color: var(--red);
    transition: 0.4s;
  }
  .btn_chat_bot:hover{
    background-color: transparent;
    scale: 1.2;
  }
  .btn_chatbot_icon{
    font-size: 80px;
  }

  .btn_close_bot {
    margin-left: auto !important;
    width: 40px;
    display: flex;  
    justify-content: center;  
    align-items: center;  
    height: 30px; 
    padding: 0; 
    border: 2px solid var(--blue);
    background-color: transparent;
  }
  
  .btn_close_icon {
    width: 30px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    color: var(--blue);
  }
  